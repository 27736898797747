<template>
    <div class="main-box">
        <div class="con-box">
            <div class="title">
                <p>深度分析报告</p>
                <div class="tianbao" @click="onAdd">专属深度报告数据填报</div>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="rList" :loading="loading" :pagination="pagination" @change="onPage">
                    <template #diagnosis="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #creditReporting="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #deepAnalysis="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #operation="{record}">
                        <div v-if="rList.length">
                            <a-button type="primary" v-if="record.CREATION_STATUS === 'N'" @click="$router.push('/ReportForms/ZCFZ')">修改</a-button>
<!--                            <a-button style="margin-left:20px" type="primary" v-if="record.CREATION_STATUS === 'N'" @click="pay(record.ENTERDEPTHREPORT_ID)">支付</a-button>-->
                            <a-button style="margin-left:20px" type="primary" v-if="record.CREATION_STATUS === 'N'" @click="exportRep(record.ENTERDEPTHREPORT_ID)">生成</a-button>
                            <div v-if="record.CREATION_STATUS === 'Y'" style="color: #999">
                                报告已生成，3个工作日内平台业务人员 会与您联系，请耐心等待...
                            </div>

                          <a-button style="margin-left:20px" type="primary" v-if="record.CREATION_STATUS === 'N'" @click="Knowledge(record.ENTERDEPTHREPORT_ID)">生成知识图谱</a-button>
<!--                            <a-button style="margin-left:20px" type="primary"  v-if="record.CREATION_STATUS === 'Y'"   @click="repDown(record.ENTERDEPTHREPORT_ID)">查看</a-button>-->
<!--                            <a-button style="margin-left:20px" type="primary"   v-if="record.CREATION_STATUS === 'Y'"  @click="repDown(record.ENTERDEPTHREPORT_ID)">下载</a-button>-->
<!--                            <a-button style="margin-left:20px" type="primary"  @click="$message.info('此项功能暂未开通')">未付费</a-button>-->
                        </div>
                    </template>
                </a-table>
            </div>
        </div>
      <div ref="replayModal">
        <a-modal v-model:visible="visible" title="企业知识图谱"   width="75%" >
          <a-button key="back"  @click="downloadImpByChart">保存到服务器</a-button>
          <div id="myChart" style="width:1200px;height:950px" ref="myChart"></div>
          <template #footer>
            <a-button key="back" @click="closure">关闭</a-button>
          </template>

        </a-modal>
      </div>
      <div ref="weChatPay">
        <a-modal v-model:visible="payVisible"  :closable=false :maskClosable=false title="微信支付">
          <div id="payQrcode"></div>
          <template #footer>
            <a-button key="back" @click="payClosure">关闭</a-button>
          </template>

        </a-modal>
      </div>
    </div>
</template>

<script>
import {defineComponent,ref} from 'vue'
import { mapGetters } from 'vuex'
import * as echarts from 'echarts'
import QRCode from 'qrcodejs2';
export default defineComponent({
    computed: {
        ...mapGetters(['orgId', 'isAuth', 'loginType', 'orgName'])

    },
    setup() {
        // 表格A企业资源
        const columns = [
            {
                title: '序号',
                dataIndex: 'key',
                key: 'key',
                align:'center',
                width: 60
            },
            {
                title: '报告名称',
                dataIndex: 'reportName',
                key: 'reportName',
                width: '25%',
                align:'center'
            },
            {
                title: '季度',
                dataIndex: 'jidu',
                key: 'jidu',
                width: '15%',
                align:'center'
            },
            {
                title: '生成时间',
                dataIndex: 'generatedTime',
                key: 'generatedTime',
                width: '15%',
                align:'center'
            },
            {
                title: '操作',
                dataIndex: 'operation',
                align:'center',
                width:'35%',
                slots: {
                    customRender: 'operation'
                }
            }
        ]
        return {
            columns
        }
    },
    data () {
        return {
            page: 1,
            rList: [],
            loading: false,
            pagination: {},
          visible:false,
          payVisible:false,
          chartData:[{
            name: 'node01',
            des: 'nodedes01',
            symbolSize: 70,
            category: 0,
            itemStyle: {
              color: '#797979'
            },
          }, {
            name: 'node02',
            des: 'nodedes02',
            symbolSize: 60,
            category: 0
          }, {
            name: 'node03',
            des: 'nodedes3',
            symbolSize: 60,
            category: 0,
          }, {
            name: 'node04',
            des: 'nodedes04',
            symbolSize: 60,
            category: 0,
          }, {
            name: 'node05',
            des: 'nodedes05',
            symbolSize: 60,
            category: 0,
          }, {
            name: 'node06',
            des: 'nodedes06',
            symbolSize: 40,
            category: 1
          }, {
            name: 'node07',
            des: 'nodedes07',
            symbolSize: 40,
            category: 1,
          }, {
            name: 'node08',
            des: 'nodedes08',
            symbolSize: 40,
            category: 1,
          }, {
            name: 'node09',
            des: 'nodedes09',
            symbolSize: 40,
            category: 1,
          }, {
            name: 'node10',
            des: 'nodedes10',
            symbolSize: 40,
            category: 1,
          }, {
            name: 'node11',
            des: 'nodedes11',
            symbolSize: 40,
            category: 2,
          }],
          links:[{
            source: 'node01',
            target: 'node02',
            name: 'link01',
            des: 'link01des'
          }, {
            source: 'node01',
            target: 'node03',
            name: 'link02',
            des: 'link02des'
          }, {
            source: 'node01',
            target: 'node04',
            name: 'link03',
            des: 'link03des'
          }, {
            source: 'node01',
            target: 'node05',
            name: 'link04',
            des: 'link04des'
          }, {
            source: 'node05',
            target: 'node06',
            name: 'link05',
            des: 'link05des'
          }, {
            source: 'node02',
            target: 'node07',
            name: 'link06',
            des: 'link06des'
          }, {
            source: 'node03',
            target: 'node08',
            name: 'link07',
            des: 'link07des'
          }, {
            source: 'node04',
            target: 'node09',
            name: 'link08',
            des: 'link08des'
          }, {
            source: 'node05',
            target: 'node10',
            name: 'link09',
            des: 'link09des'
          }, {
            source: 'node10',
            target: 'node11',
            name: 'link10',
            des: 'link10des'
          }],
          myChart:undefined,
          // 微信支付
          WeChatPayOut_trade_no:'',
          ispay:true,
          //二维码具体指
          qr: '',
        }
    },
    methods: {
        // 添加一条深度报告
        onAdd () {
            let m = ''
            if (new Date().getMonth() + 1 == 1 || new Date().getMonth() + 1 == 2 || new Date().getMonth() + 1 == 3) {
                m = new Date().getFullYear() + '01'
            } else if (new Date().getMonth() + 1 == 4 || new Date().getMonth() + 1 == 5 || new Date().getMonth() + 1 == 6) {
                m = new Date().getFullYear() + '02'
            } else if (new Date().getMonth() + 1 == 7 || new Date().getMonth() + 1 == 8 || new Date().getMonth() + 1 == 9) {
                m = new Date().getFullYear() + '03'
            } else if (new Date().getMonth() + 1 == 10 || new Date().getMonth() + 1 == 11 || new Date().getMonth() + 1 == 12) {
                m = new Date().getFullYear() + '04'
            }
            this.$store.dispatch('addDeepReport', {
                ENTERPRISE_ID: this.orgId,
                ENTERPRISE_NAME: this.orgName,
                QUARTER: m,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('添加成功！')
                    this.page = 1
                    this.getDeepReportList()
                } else if (res.result === 'repeat') {
                    this.$message.info('当前季度已存在记录，不能重复添加！')
                } else {
                    this.$message.error('系统异常，添加深度报告失败，请稍后重试！')
                }
            })
        },
        exportRep(id){
            this.loading = true
            this.$store.dispatch('GetDeepReportEdit', {
                ENTERPRISE_ID: this.orgId,
                ENTERDEPTHREPORT_ID: id,
                tm: new Date().getTime()
            }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$message.success('深度报告生成成功！')
                    this.page = 1
                    this.getDeepReportList()
                } else if (res.result === 'warning'){
                  this.$message.error('没有生成知识图谱，请先生成知识图谱！！！')
                }else {
                    this.$message.error('系统异常，深度报告生成失败，请稍后重试！')
                }
            })
        },
      //生成知识图谱
      Knowledge(e){
          //打开弹框
        this.visible = true;
        //获取知识图谱数据
        this.getKnowledgeData(e)
      },
      //保存知识图谱
      downloadImpByChart(){
        var url = this.myChart.getConnectedDataURL({
          pixelRatio: 1, //导出的图片分辨率比率,默认是1
          backgroundColor: '#fff', //图表背景色
          excludeComponents: [ //保存图表时忽略的工具组件,默认忽略工具栏
            'toolbox'
          ],
          type: 'png' //图片类型支持png和jpeg
        });
        this.$store.dispatch('DownloadImpByChart', {
          enterpriseId: this.orgId,
          picInfo: url
        }).then(res => {
          if (res.result === 'success') {
            this.$message.success('保存成功！')
            this.closure()
          }  else {
            this.$message.error('系统异常，保存失败，请稍后重试！')
          }
        })
      },
      //关闭
      closure(){
        this.visible = false;
      },
      //关闭
      payClosure(){
        this.payVisible = false;
        this.isShowOpen();
        //别忘了清除定时器
        clearInterval(this.timers);

      },
        repDown(id){
            this.$router.push('company')
            // window.location.href = this.$downDeepRep+'?ENTERDEPTHREPORT_ID='+id
        },

        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getDeepReportList()
        },
        // 获取深度报告列表
        getDeepReportList () {
            this.rList = []
            this.loading = true
            this.$store.dispatch('getDeepReportList', {
                showCount: 10,
                currentPage: this.page,
                ENTERPRISE_ID: this.orgId,
                tm: new Date().getTime()
            }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        this.pagination.total = res.page.totalResult
                        for (let i in datas) {
                            let jd = '', jd1 = ''
                            if (datas[i].QUARTER && datas[i].QUARTER !== '') {
                                jd = datas[i].QUARTER.substring(0,4)
                                jd1 = datas[i].QUARTER.substring(4).replace(/\b(0+)/gi,"")
                            }
                            this.rList.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'reportName': datas[i].ENTERPRISE_NAME + '深度分析报告',
                                'jidu': jd+'年第'+jd1+'季度',
                                'generatedTime': (datas[i].CREATION_DATE && datas[i].CREATION_DATE !== '') ? datas[i].CREATION_DATE : '',
                                'CREATION_STATUS': datas[i].CREATION_STATUS,
                                'ENTERDEPTHREPORT_ID': datas[i].ENTERDEPTHREPORT_ID,
                            })
                        }
                        datas.map((item,index)=>{
                            item.QUARTER.substring(0,3)
                            console.log(item.QUARTER.substring(0,4))
                        })
                    } else {
                        this.rList = []
                        this.pagination.total = 0
                    }
                } else {
                    this.rList = []
                    this.pagination.total = 0
                    this.$message.error('系统异常，获取深度报告列表失败，请稍后重试！')
                }
            })
        },
      //获取知识图谱数据
      getKnowledgeData(e){
        this.$store.dispatch('getKnowledgeData', {
          ENTERPRISE_ID: this.orgId,
        }).then(res => {
          if (res.result === 'success') {
            this.chartData = res.data;
            this.links = res.links;

          } else {
            this.$message.error('系统异常，获取知识图谱数据失败，请稍后重试！')
            this.visible = false
          }
          this.drawLine()
        })

      },
      drawLine(){
        // 基于准备好的dom，初始化echarts实例
        this.myChart = echarts.init(this.$refs.myChart)
        var categories = [{
          name: '正常',
          itemStyle: {
            color: '#6699ff'
          }
        }, {
          name: '预警',
          itemStyle: {
            color: '#ff9900'
          }
        }, {
          name: '异常',
          itemStyle: {
            color: '#ff2918'
          }
        }, {
          name: '变动',
          itemStyle: {
            color: '#1dff15'
          }
        }];
        let  option={
          // 提示框的配置
          tooltip: {
            formatter: function (x) {
              return x.data.des;
            }
          },
          // 工具箱
          toolbox: {
            // 显示工具箱
            show: true,
            feature: {
              mark: {
                show: true
              },
              // 还原
              restore: {
                show: true
              },
              // 保存为图片
              saveAsImage: {
                show: true
              }
            }
          },
          series: [{
            type: 'graph', // 类型:关系图
            layout: 'force', //图的布局，类型为力导图
            symbolSize: 40, // 调整节点的大小
            roam: true, // 是否开启鼠标缩放和平移漫游。默认不开启。如果只想要开启缩放或者平移,可以设置成 'scale' 或者 'move'。设置成 true 为都开启
            edgeSymbol: ['circle', 'arrow'],
            edgeSymbolSize: [2, 10],
            edgeLabel: {
              normal: {
                textStyle: {
                  fontSize: 20
                }
              }
            },
            force: {
              repulsion: 3000,
              edgeLength: [10, 50]
            },
            draggable: true,
            lineStyle: {
              normal: {
                width: 2,
                color: '#4b565b',
              }
            },
            edgeLabel: {
              normal: {
                show: true,
                formatter: function (x) {
                  return x.data.name;
                }
              }
            },
            label: {
              normal: {
                show: true,
                textStyle: {}
              }
            },
            data: this.chartData,
            // 数据
            /*data: [{
              name: 'node01',
              des: 'nodedes01',
              symbolSize: 70,
              category: 0,
              itemStyle: {
                color: '#797979'
              },
            }, {
              name: 'node02',
              des: 'nodedes02',
              symbolSize: 60,
              category: 0
            }, {
              name: 'node03',
              des: 'nodedes3',
              symbolSize: 60,
              category: 0,
            }, {
              name: 'node04',
              des: 'nodedes04',
              symbolSize: 60,
              category: 0,
            }, {
              name: 'node05',
              des: 'nodedes05',
              symbolSize: 60,
              category: 0,
            }, {
              name: 'node06',
              des: 'nodedes06',
              symbolSize: 40,
              category: 1
            }, {
              name: 'node07',
              des: 'nodedes07',
              symbolSize: 40,
              category: 1,
            }, {
              name: 'node08',
              des: 'nodedes08',
              symbolSize: 40,
              category: 1,
            }, {
              name: 'node09',
              des: 'nodedes09',
              symbolSize: 40,
              category: 1,
            }, {
              name: 'node10',
              des: 'nodedes10',
              symbolSize: 40,
              category: 1,
            }, {
              name: 'node11',
              des: 'nodedes11',
              symbolSize: 40,
              category: 2,
            }],*/
            links: this.links,
           /*links: [{
              source: 'node01',
              target: 'node02',
              name: 'link01',
              des: 'link01des'
            }, {
              source: 'node01',
              target: 'node03',
              name: 'link02',
              des: 'link02des'
            }, {
              source: 'node01',
              target: 'node04',
              name: 'link03',
              des: 'link03des'
            }, {
              source: 'node01',
              target: 'node05',
              name: 'link04',
              des: 'link04des'
            }, {
              source: 'node05',
              target: 'node06',
              name: 'link05',
              des: 'link05des'
            }, {
              source: 'node02',
              target: 'node07',
              name: 'link06',
              des: 'link06des'
            }, {
              source: 'node03',
              target: 'node08',
              name: 'link07',
              des: 'link07des'
            }, {
              source: 'node04',
              target: 'node09',
              name: 'link08',
              des: 'link08des'
            }, {
              source: 'node05',
              target: 'node10',
              name: 'link09',
              des: 'link09des'
            }, {
              source: 'node10',
              target: 'node11',
              name: 'link10',
              des: 'link10des'
            }],*/
            categories: categories,
          }]
        };
        // 绘制图表
        this.myChart.setOption(option);
      },
      //微信支付
      pay(id){
        //打开弹框
        this.payVisible = true;
        // 生成二维码
        this.createNative(id);
      },
      //预下单生成二维码
      createNative(id){
        this.$store.dispatch('createNative').then(res => {
          if (res.code === 0) {
            this.WeChatPayOut_trade_no = res.out_trade_no;
            this.GenerateQRcode(res.QrCode,id);
          } else {
            this.$message.error(res.msg)
            this.payVisible = false
          }
        })
      },
      // 生成二维码
      GenerateQRcode(val,id){
       new QRCode("payQrcode", { // 此处的qrcode为上面div的id
          text: val,
          width: 450,
          height: 400,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H
        });
        let _this = this;
        _this.getOrderstate(id)
      },

      //清除生成的二维码
      isShowOpen() {
        const codeHtml = document.getElementById("payQrcode");
        codeHtml.innerHTML = "";
      },

      getOrderstate(id){ //判断支付是否成功，监听微信支付是否成功
        let _this = this;
        let num = 0;
        _this.timers = setInterval(()=>{
          num++;
          this.$store.dispatch('QueryPayStatus', {
            out_trade_no: this.WeChatPayOut_trade_no,
            ENTERDEPTHREPORT_ID: id,
          }).then(res=>{
            if (res.code == 0 && res.OrderCode == "USERPAYING") {
              //订单已经创建但未支付（用户扫码后但是未支付）
              if (this.ispay) {
                console.log("支付未成功");
                this.ispay = false;
              }
            } else if (res.code == 0 && res.OrderCode == "SUCCESS") {
              this.payVisible = false;
              this.isShowOpen();
              clearInterval(this.timers)
              this.$message.success('支付成功');
              this.page = 1;
              this.getDeepReportList();
              // setTimeout(()=>{
              //   this.$router.go(-1)
              // },500)
            }
          });
          if(num == 500){
            this.ispay = false,
            //别忘了清除定时器
            clearInterval(this.timers)
          }
        },1500)
      },
    },
    created () {
        // $router.push('/ReportForms/ZCFZ')
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        // 获取深度报告列表
        this.getDeepReportList()


    }
})
</script>
<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
            .title-right{
                display: flex;
                justify-content: space-between;
            }
            .tianbao {
                border: 1px solid #457dfe;
                border-radius: 5px;
                padding: 2px 15px;
                color: #457dfe;
                cursor: pointer;
            }
        }
        .content-box{
            margin-top: 20px;
            font-size: 16px;
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
